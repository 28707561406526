<template>
    <div class="publication-summary">
        <div class="main">
            <div class="meta">
                <div class="meta-left">
                    <TypeLabel v-if="subtype && !subtypeLabel" :name="subtype" :pageLanguage="pageLanguage" />
                    <TypeLabel v-if="subtypeLabel" :name="subtype" :customLabel="subtypeLabel" />
                    <AttributionLabel v-if="attributions" :attributions="attributions" :pageLanguage="pageLanguage">
                    </AttributionLabel>
                </div>

                <div class="meta-right" v-if="showReadTime">
                    <Typography class="readtime" variant="label">45 MIN READ</Typography>
                </div>
            </div>

            <div class="head">
                <Typography as="h1" variant="h2">{{ title }}</Typography>
                <RichTextElements
                    v-if="dek"
                    :elements="dek"
                    bodyStyle="sans-large"
                    pageContext="publication"
                    class="dek"
                />
            </div>

            <div class="controls">
                <div v-if="primaryLink?.url || translationMenuItems || pdfResourcesMenuItems.length" class="resources">
                    <template v-if="primaryLink?.url">
                        <Button
                            v-if="primaryLink?.url"
                            :url="primaryLink.file?.url || primaryLink.url"
                            :newTab="isLinkExternal(primaryLink)"
                            variant="primary"
                            class="download-button control"
                        >
                            {{ primaryLink.label }}
                        </Button>
                    </template>

                    <ContentLanguageSelector class="translation-select control" :languages="translationMenuItems" />
                    <DropdownMenu
                        v-if="pdfResourcesMenuItems.length"
                        :items="pdfResourcesMenuItems"
                        icon="download"
                        menuName="translation-pdfs"
                        class="translation-pdf-select control"
                    >
                        <template #label>PDF</template>
                    </DropdownMenu>
                </div>

                <div class="social-buttons">
                    <div class="control-set">
                        <div class="control share-buttons">
                            <ShareButton />
                            <CopyLinkButton />
                            <PrintButton />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="credits">
            <div v-if="contributors.length" class="contributors">
                <Typography variant="body" transform="capitalize" v-if="pageLanguage !== 'hi'"
                    >{{
                        $t('by', 1, {
                            locale: pageLanguage,
                        })
                    }}&nbsp;</Typography
                >
                <Typography v-for="(person, index) in contributors" :key="person.firstName" variant="body">
                    <Anchor v-if="person.path && (person.type === 'internal' || person.hasProfile)" :url="person.path">
                        {{ getPersonFullName(person) }}</Anchor
                    >
                    <span v-else>{{ getPersonFullName(person) }}</span>

                    <template v-if="index === contributors.length - 2">
                        <template v-if="contributors.length > 2">,</template>
                        <template v-else-if="contributors.length > 1 && pageLanguage === 'ru'">,</template>
                        <template v-if="pageLanguage !== 'ru'"
                            ><span>&nbsp;</span>{{ $t('and', 1, { locale: pageLanguage })
                            }}<span>&nbsp;</span></template
                        >
                        <template v-else>&nbsp;</template>
                    </template>
                    <template v-else-if="index !== contributors.length - 1">,&nbsp;</template>
                </Typography>
            </div>

            <div v-if="publisherName || publishDate" class="publisher">
                <template v-if="publisherName">
                    <Typography variant="body-micro" transform="capitalize-first-letter"
                        >{{
                            $t('published by', 1, {
                                locale: pageLanguage,
                            })
                        }}
                    </Typography>
                    <RichTextElements v-if="publisherName" :html="publisherName" bodyStyle="body-micro-pub-name" />
                    <Typography v-if="publishDate" variant="body-micro"
                        >&nbsp;{{
                            $t('on', 1, {
                                locale: pageLanguage,
                            })
                        }}
                        {{ publishDate }}</Typography
                    >
                </template>
                <template v-else-if="publishDate">
                    <div class="bottom-meta">
                        <Typography variant="body-micro" transform="capitalize-first-letter"
                            >{{
                                $t('Published on', 1, {
                                    locale: pageLanguage,
                                })
                            }}
                            {{ publishDate }}
                        </Typography>

                        <div v-if="$slots.supplementalMeta" class="supplemental-meta">
                            <span class="meta-separator">&bull;</span>

                            <Typography variant="body">
                                <slot name="supplementalMeta"></slot>
                            </Typography>
                        </div>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script setup>
const props = defineProps({
    title: {
        type: String,
        default: '',
        required: true,
    },
    subtype: {
        type: String,
        default: '',
    },
    attributions: {
        type: [Array, Object],
    },
    dek: {
        type: Object,
        default: () => {},
    },
    translations: {
        type: Object,
        default: () => {},
    },
    pdfResources: {
        type: Array,
        default: () => [],
    },
    contributors: {
        type: Array,
        default: () => [],
    },
    publisherName: {
        type: String,
        default: '',
    },
    publishedAt: {
        type: String,
        default: '',
    },
    primaryLink: {
        type: Object,
    },
    showReadTime: {
        type: Boolean,
        default: false,
    },
    subtypeLabel: {
        type: String,
        default: '',
    },
    pageLanguage: {
        type: String,
        default: '',
    },
});
const { locales } = useI18n();

const translationMenuItems = computed(() => {
    const items = [];
    if (props?.translations) {
        Object.keys(props.translations).forEach((key) => {
            if (props.translations[key]) {
                items.push({
                    code: key,
                    name: getLocaleName(locales, key) || key,
                    url: `?lang=${key}`,
                });
            }
        });
    }

    return items;
});

const pdfResourcesMenuItems = computed(() =>
    props.pdfResources.map((item) => {
        return {
            label: item.label || item.filename || '',
            url: item.url || item.file.url,
        };
    }),
);
const publishDate = computed(() => {
    return formatDateIntnl(props.publishedAt, props.pageLanguage);
});
</script>

<style lang="scss" scoped>
.meta {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
    width: 100%;
}

.meta-left {
    display: flex;
    align-items: center;
    gap: 1.2rem;
}

.readtime {
    color: color(offnoir);
    opacity: 0.5;
}

.dek {
    font-weight: 400;
    opacity: 0.75;
}

.controls {
    padding-top: 1.6rem;
    display: flex;
    align-items: center;

    @include media-query(mobile) {
        flex-direction: column;
        align-items: flex-start;
        gap: vertical-space(1);
    }
}

.control {
    display: block;
    height: 100%;
}

.resources {
    display: flex;
    align-items: center;
}

.download-button {
    flex: 1;
}

.translation-select,
.translation-pdf-select {
    padding: 1.8rem 2.4rem;
    border-inline-end: 1px solid palette(divider);

    @include media-query(phone) {
        padding-inline-start: 2rem;
        padding-inline-end: 2rem;
    }
}

.translation-select {
    &:first-child {
        padding-inline-start: 0;
    }
}

.translation-pdf-select {
    @include media-query(mobile) {
        border-inline-end: 0;
    }
}

.social-buttons {
    padding-inline-end: 2.4rem;

    &:not(:only-child) {
        padding-inline-start: 2.4rem;
    }

    @include media-query(mobile) {
        padding-inline-start: 0;
    }
}

.main {
    padding-bottom: 2.4rem;
    border-bottom: 1px solid palette(divider);
}

.credits {
    padding-top: 2.4rem;
}

.publisher {
    margin-top: 0.2rem;
    color: color(grey, x-dark);
}

.share-buttons {
    display: flex;
    gap: 0.4rem;
    position: relative;
    @include z-index(arbitrary);
}

.supplemental-meta {
    display: inline-flex;
    align-items: center;
}

.meta-separator {
    padding: 0 0.5em;
}
</style>
